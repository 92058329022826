import React, { useEffect, useState } from "react";
import useGlobal from "../store";
import Form from "./Form";
import Wall from "./Wall";
import Pagination from "./Pagination";
import Search from "./Search";

export default function App(props) {
  const [globalState, globalActions] = useGlobal();

  /**
   * Renders the HTML for a pledge.
   * @param {object} pledge - The pledge record
   * @return {JSX} the HTML for the pledge
   */
  const pledgeBox = function(pledge) {    
    return(<figure key={pledge.id} className="wall-item">
      <div className="wall-item-aspect">
        <figcaption className="wall-item-name">{pledge.displayLast ? pledge.lastName : pledge.firstName}
        { displayCity(pledge) }
        { displayJobTitle(pledge) }
        { displayJobDescription(pledge) }
        </figcaption>
				<div className="wall-item-photo" style={{ backgroundImage: "url(" + pledge.pledgeUrl + ")"  }}></div>
      </div>
    </figure>);
  }
  
  const displayCity = function(pledge){
    if (pledge.state){
      return(<div className="wall-item-city">{ pledge.state }</div>);
    }
  }
  
  const displayJobTitle = function(pledge){
    if (pledge.jobTitle){
      return(<div className="wall-item-title">{ pledge.jobTitle }</div>);
    }
  }
  
  const displayJobDescription = function(pledge){
    if (pledge.jobDescription){
      return(<div className="wall-item-description">{ pledge.jobDescription }</div>);
    }
  }

  // componentDidMount
  useEffect(() => {
    globalActions.setPledges(props.pledges);
    globalActions.setPage(props.page);
    globalActions.setSearching(false);
    globalActions.setSearchTerm(false);
    globalActions.setFormExpanded(false);
  }, []);

  return(<React.Fragment>
    <Form pledgeBox={pledgeBox} />
    <Wall pledgeBox={pledgeBox} />
    <Pagination pages={props.pages} />
    <Search />
  </React.Fragment>);
}
