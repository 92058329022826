import React, { useState } from "react";
import useGlobal from "../store";
import axios from "axios";

export default function Pagination(props) {
  const [globalState, globalActions] = useGlobal();

  const firstLink = function() {
    if(globalState.page === 1) {
      return(<li>First</li>);
    } else {
      return (<li><a href="#wall" onClick={() => changePage(1)}>First</a></li>);
    }
  }

  const backLink = function() {
    if(globalState.page > 1) {
      return (<li><a href="#wall" onClick={() => changePage(globalState.page - 1)}>Back</a></li>);
    }
  };

  const dropdown = function() {
    const options = [...Array(props.pages).keys()].map((n) => (<option key={n} value={n+1}>{n+1}</option>));
    return(<select value={globalState.page} onChange={(e) => changePage(e.target.value)}>{options}</select>);
  };

  const nextLink = function() {
    if(globalState.page < props.pages) {
      return (<li><a href="#wall" onClick={() => changePage(globalState.page + 1)}>Next</a></li>);
    }
  };

  const lastLink = function() {
    if(globalState.page === props.pages) {
      return(<li>Last</li>);
    } else {
      return (<li><a href="#wall" onClick={() => changePage(props.pages)}>Last</a></li>);
    }
  };

  const changePage = function(page) {
    axios({
      url: `/?page=${page}&format=json`,
      method: "GET",
      headers: { "X-CSRF-TOKEN": document.querySelector("[name=csrf-token]").content }
    }).then(function(response){
      globalActions.setPledges(JSON.parse(response.data), false, true);
      globalActions.setPage(page);
    });
  };

  if(globalState.searching) {
    return(<></>);
  } else {
    return(<nav className="pagination">
      <ul className="pagination-list">
        { globalState.page != 1 && firstLink() }
        {backLink()}
        {dropdown()}
        {nextLink()}
        { globalState.page != props.pages && lastLink() }
      </ul>
    </nav>);
  }
}