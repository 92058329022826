import React, { useEffect, useRef, useState } from "react";
import useGlobal from "../store";
import axios from "axios";

export default function AdminApp(props) {
  const [globalState, globalActions] = useGlobal();
  const [view, setView] = useState("unapproved");

  const changeStatus = function(e, pledge) {
    axios({
      url: `/admin/pledges/${pledge.id}.json`,
      method: "PATCH",
      data: {pledge: {status: e.target.value}},
      headers: {"X-CSRF-TOKEN": document.querySelector("[name=csrf-token]").content}
    }).then(function(response) {
      globalActions.updatePledge(pledge.id, {status: response.data.pledge.status});
    });
  };

  const changeSticky = function(e, pledge) {
    axios({
      url: `/admin/pledges/${pledge.id}.json`,
      method: "PATCH",
      data: {pledge: {sticky: !pledge.sticky}},
      headers: {"X-CSRF-TOKEN": document.querySelector("[name=csrf-token]").content}
    }).then(function(response) {
      globalActions.updatePledge(pledge.id, {sticky: response.data.pledge.sticky});
    });
  };
  
    const changeDisplay = function(e, pledge) {
    axios({
      url: `/admin/pledges/${pledge.id}.json`,
      method: "PATCH",
      data: {pledge: {display_last: !pledge.displayLast}},
      headers: {"X-CSRF-TOKEN": document.querySelector("[name=csrf-token]").content}
    }).then(function(response) {
      globalActions.updatePledge(pledge.id, {displayLast: response.data.pledge.display_last});
    });
  };

  const changePhoto = function(photo, pledge) {
    if(confirm("Are you sure? You cannot restore this user's original photo!")) {
      axios({
        url: `/admin/pledges/${pledge.id}.json`,
        method: "PATCH",
        data: {pledge: {pledge_url: photo}},
        headers: {"X-CSRF-TOKEN": document.querySelector("[name=csrf-token]").content}
      }).then(function(response) {
        globalActions.updatePledge(pledge.id, {pledgeUrl: response.data.pledge.pledge_url});
      });
    }
  };

  /**
   * Renders the HTML for a pledge.
   * @param {object} pledge - The pledge record
   * @return {JSX} the HTML for the pledge
   */
  const pledgeBox = function(pledge) {
    return(<div key={pledge.id} className={`admin-item ${pledge.status}`}>

      <div className="admin-item-photo">
        <img className="admin-item-photo-img" src={pledge.pledgeUrl} />
      </div>
      
      <div className="admin-item-details">

            <div className="admin-item-row">
              <span className="admin-item-row-label">Status:</span> 
              <span className="admin-item-row-value">
                <select value={pledge.status} onChange={(e) => changeStatus(e, pledge)}>
                  <option value="unapproved">Unapproved</option>
                  <option value="not_approved">Not Approved</option>
                  <option value="approved">Approved</option>
                </select>
              </span>
            </div>

            <div className="admin-item-row">
              <span className="admin-item-row-label">Sticky:</span>
              <span className="admin-item-row-value">{pledge.sticky ? 'Yes' : 'No'} (<u><a style={{color: "#000"}} href="#" onClick={(e) => changeSticky(e, pledge)}>change</a></u>)</span>
            </div>
            
            <div className="admin-item-row">
              <span className="admin-item-row-label">Display Last Name?</span>
              <span className="admin-item-row-value">{pledge.displayLast ? 'Yes' : 'No'} (<u><a style={{color: "#000"}} href="#" onClick={(e) => changeDisplay(e, pledge)}>change</a></u>)</span>
            </div>

            <div className="admin-item-row">
              <span className="admin-item-row-label">Name:</span>
              <span className="admin-item-row-value">{pledge.firstName} {pledge.lastName}</span>
            </div>
            
            <div className="admin-item-row">
              <span className="admin-item-row-label">Email:</span>
              <span className="admin-item-row-value">{pledge.email}</span>
            </div>
            
            <div className="admin-item-row">
              <span className="admin-item-row-label">City:</span>
              <span className="admin-item-row-value">{pledge.city}</span>
            </div>
            
            <div className="admin-item-row">
              <span className="admin-item-row-label">State:</span>
              <span className="admin-item-row-value">{pledge.state}</span>
            </div>
            
            <div className="admin-item-row">
              <span className="admin-item-row-label">Job Title:</span>
              <span className="admin-item-row-value">{pledge.jobTitle}</span>
            </div>
            
            <div className="admin-item-row">
              <span className="admin-item-row-label">Job Descrption:</span>
              <span className="admin-item-row-value">{pledge.jobDescription}</span>
            </div>
          
      
      </div>

    </div>);
  }

  /**
   * Returns only the pledges that match the current view.
   * @param {array} pledges - All of the pledges
   * @return {array} The filtered pledges
   */
  const viewFilter = function(pledges) {
    return pledges.filter((p) => p.status === view);
  };

  // componentDidMount
  useEffect(() => {
    globalActions.setPledges(props.pledges);
  }, []);

  useEffect(() => {
    // console.log("Render");
  });

  const filteredPledges = viewFilter(globalState.pledges);

  return(<React.Fragment>
    <div className="admin-filters">
      <span className="admin-filters-label">Filter by status:</span>
      <button className={`unapproved`} onClick={() => setView("unapproved")}>New</button>
      <button className={`approved`} onClick={() => setView("approved")}>Approved</button>
      <button className={`not_approved`} onClick={() => setView("not_approved")}>Not Approved</button>
    </div>
    <h3 className="admin-filters-showing">Showing {filteredPledges.length} Pledges</h3>
        
    <section id="wall">
      {filteredPledges.map((pledge) => pledgeBox(pledge))}
    </section>
  </React.Fragment>);
}
