import React from "react";
import useGlobalHook from "use-global-hook";

const actions = {
  setPage: (store, page) => {
    store.setState({page: page});
  },

  setPledges: (store, pledges, prepend=false, replace=false) => {
    let newPledges;

    if(replace) {
      newPledges = []
    } else {
      newPledges = store.state.pledges;
    }

    pledges.forEach(function(pledge){
      if(prepend) {
        newPledges.unshift(pledge);
      } else {
        newPledges.push(pledge)
      }
    });

    store.setState({pledges: newPledges});
  },

  setSearching: (store, value) => {
    store.setState({searching: value});
  },

  setSearchTerm: (store, term) => {
    store.setState({searchTerm: term});
  },

  setFormExpanded: (store, expanded) => {
    store.setState({formExpanded: expanded});
  },

  updatePledge: (store, pledgeId, newData) => {
    let newPledges = store.state.pledges;
    newPledges = newPledges.map(function(pledge) {
      if(pledge.id === pledgeId) {
        pledge = Object.assign({}, pledge, newData);
      }

      return pledge;
    });
    store.setState({pledges: newPledges});
  }
};

const initialState = {pledges: [], page: 1, searching: false, searchTerm: "", formExpanded: false};
const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
