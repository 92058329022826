import Rails from "@rails/ujs"
import React from "react";
import App from "../components/App";
import AdminApp from "../components/AdminApp";
import ReactDOM from "react-dom";
import "stylesheets/styles";

Rails.start()

if(document.getElementById('app')) {
  ReactDOM.render(
    <App pledges={window.pledges} page={window.currentPage} pages={window.pages} />,
    document.getElementById('app')
  );
} else if(document.getElementById('admin-app')) {
  ReactDOM.render(
    <AdminApp pledges={window.pledges} />,
    document.getElementById('admin-app')
  );  
}
